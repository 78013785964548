import { User } from "@the-volte/svc-core-sdk/lib/fragments/gqlTypes/User";
import axios from "axios";

import { TikTokEventData } from "@app/providers/TikTokPixelProvider";

import { decodeBase64Id } from "./base64";
import { hashData } from "./hashData";

export const sendTikTokEvent = async (
  event: string,
  data: TikTokEventData,
  user?: User
) => {
  axios
    .post("/api/tik-tok-events", {
      contents: data.contents,
      currency: data.currency,
      event,
      event_id: data.event_id,
      location: data?.location,
      userAgent: data?.userAgent,
      value: data.value,
      ...(user && {
        email: await hashData(user.email),
        external_id: await hashData(decodeBase64Id(user.id)),
        phone_number: await hashData(user.defaultBillingAddress?.phone || ""),
      }),
    })
    .catch((e) => {
      console.error("Error sending TikTok event", e);
    });
};
