import { useAuth } from "@the-volte/svc-core-sdk";
import { ProductDetails } from "@the-volte/svc-core-sdk/lib/fragments/gqlTypes/ProductDetails";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

import { IProduct } from "@types";
import { decodeBase64Id, encode } from "@utils/base64";
import { hashData } from "@utils/hashData";
import { sendTikTokEvent } from "@utils/tiktok";

const TIK_TOK_EVENT_ID_PREFIX = "tt_ev_";

type ContentData = {
  brand: string;
  content_id: string;
  content_name: string;
  content_type: string;
  price: number | null;
  quantity?: number;
};

export type TikTokEventData = {
  contents?: Array<ContentData>;
  currency?: string;
  description?: string;
  email?: string;
  event_id?: string;
  external_id?: string;
  phone_number?: string;
  query?: string;
  value?: number | null;
  location?: string;
  userAgent?: string;
};

type TTQ<T> = {
  identify: (data: T) => void;
  track: (event: string, data: T) => void;
};

declare global {
  interface Window {
    ttq: TTQ<TikTokEventData>;
  }
}

type TikTokPixelContextProps = {
  track: (event: string, data: TikTokEventData) => void;
  trackProduct: (
    event: string,
    product: ProductDetails | IProduct,
    withQuantity?: boolean
  ) => void;
  clientIpAddress?: string;
};

const TikTokPixelContext = createContext<TikTokPixelContextProps | null>(null);

export const useTikTokPixelContext = () => {
  const context = useContext(TikTokPixelContext);

  if (!context) {
    throw new Error(
      "useTikTokPixelContext must be used within a TikTokPixelProvider"
    );
  }

  return context;
};

export const TikTokPixelProvider = ({
  children,
  clientIp,
}: {
  children: React.ReactNode;
  clientIp: string;
}) => {
  const [clientIpAddress, setClientIpAddress] = useState<string>();
  const { user } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (clientIp && !clientIpAddress) {
      setClientIpAddress(clientIp);
    }

    const identifyUser = async () => {
      if (typeof window !== "undefined" && window.ttq && !!user) {
        const emailHash = await hashData(user.email);
        const externalIdHash = await hashData(decodeBase64Id(user.id));
        const phoneHash = user.defaultBillingAddress?.phone
          ? await hashData(user.defaultBillingAddress.phone)
          : undefined;

        window.ttq.identify({
          email: emailHash,
          external_id: externalIdHash,
          ...(phoneHash && { phone_number: phoneHash }),
        });
      }
    };

    identifyUser();
  }, [router.pathname, user]);

  const track = (event: string, data: TikTokEventData) => {
    if (typeof window !== "undefined" && window.ttq) {
      window.ttq.track(event, data);
      const { location, navigator } = window;
      const payload = {
        ...data,
        location: location.href,
        userAgent: navigator.userAgent,
      };
      sendTikTokEvent(event, payload, user || undefined);
    }
  };

  const trackProduct = (
    event: string,
    product: ProductDetails,
    withQuantity = false
  ) => {
    if (typeof window !== "undefined" && window.ttq) {
      const pricing = product?.pricing?.priceRange?.start?.gross;
      const attribute = product?.attributes?.find(
        (attr) => attr?.attribute?.name === "Designer"
      );

      track(event, {
        contents: [
          {
            content_id: decodeBase64Id(product?.id || ""),
            content_type: "product",
            content_name: product?.name || "",
            price: pricing?.amount || null,
            brand: attribute?.values?.at(0)?.name || "",
            ...(withQuantity && { quantity: 1 }),
          },
        ],
        currency: pricing?.currency || "AUD",
        description: clientIpAddress,
        value: pricing?.amount || null,
        event_id: encode(`${TIK_TOK_EVENT_ID_PREFIX}${event}`),
      });
    }
  };

  return (
    <TikTokPixelContext.Provider
      value={{
        track,
        trackProduct,
        clientIpAddress,
      }}
    >
      {children}
    </TikTokPixelContext.Provider>
  );
};
