import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import * as C from "./constants";
import * as V from "./variables";

const legacyTheme = {
  carousel: {
    carouselControlPadding: "0.2rem 0.5rem",
    carouselControlShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
  },
  chip: {
    colors: {
      primary: {
        activeBackground: C.theme.primary[50],
        background: C.theme.primary[100],
        color: C.theme.primary[900],
        hoverBackground: "none",
        hoverColor: C.theme.primary[900],
      },
      secondary: {
        activeBackground: C.theme.primary[50],
        background: C.theme.secondary[100],
        color: C.theme.secondary[900],
        hoverBackground: "none",
        hoverColor: C.theme.secondary[900],
      },
    },
  },
  container: {
    width: 1140,
  },
  demoBanner: {
    height: "60px",
  },
  dropdown: {
    backgroundColor: C.theme.white,
    boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.15)",
  },
  grid: {
    containerWidth: 1140,
  },
  iconButton: {
    backgroundColor: C.theme.white,
    hoverBackgroundColor: C.theme.secondary,
    hoverForegroundColor: C.theme.white,
    size: 36,
  },
  input: {
    border: C.grayDark,
    labelColor: C.grayDark,
    /**
     * 12px in default theme
     */
    labelFontSize: "0.75rem",
    selectMenuShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.15)",
  },
  link: {
    base: {
      color: C.gray,
      hoverColor: C.grayMedium,
    },
    secondary: {
      color: C.accentBlue,
      hoverColor: C.accentBlueLight,
    },
  },
  message: {
    backgroundColor: C.white,
    contentMargin: `${C.spacer}rem 0 0`,
    letterSpacing: "0.5px",
    padding: "1rem 1.5rem",
    titleMargin: `0 ${C.spacer * 1.5}rem 0 0`,
    titleTransform: "uppercase",
    titleWeight: C.extraBoldFontWeight,
    width: "25rem",
  },
  modal: {
    modalMinHeight: 455,
    modalWidth: 555,
  },
  productItem: {
    productItemCategoryColor: C.gray,
    productItemPriceFontWeight: C.boldFontWeight,
    productItemPriceMargin: `${C.spacer}rem 0 0`,
    productItemTitleFontWeight: C.boldFontWeight,
    productItemTitleHeight: "2.5rem",
    productItemTitleMargin: `${C.spacer / 2}rem 0 0`,
    productItemTitleTextTransform: "uppercase",
  },
  pageTitle: {
    fontSize: "1.625rem",
  },
  checkoutTitle: {
    fontSize: "1.2rem",
  },
  genericText: {
    fontSize: "0.9rem",
  },
  genericLabel: {
    fontSize: "0.8rem",
  },
  infoText: {
    fontSize: "0.68rem",
  },
  fineprint: {
    fontSize: "0.5rem",
  },
  spacing: {
    /**
     * 30px in default theme
     */
    fieldSpacer: C.fieldSpacer,
    /**
     * 30px in default theme
     */
    gutter: "1.875rem",
    /**
     * 16px in default theme
     */
    spacer: `${C.spacer}rem`,
  },
  tile: {
    backgroundColor: C.grayLight,
    divisionLine: C.grayMedium,
    hoverBorder: C.blueDark,
  },
  typography: {
    fonts: {
      BeausiteClassic: "BeausiteClassic",
    },
    baseFontFamily: C.baseFontFamily,
    /**
     * 16px in default theme
     */
    baseFontSize: C.baseFontSize,
    /**
     * 20px in default theme
     */
    baseLineHeight: C.baseLineHeight,
    boldFontWeight: C.boldFontWeight,
    extraBoldFontWeight: C.extraBoldFontWeight,
    /**
     * 64px in default theme
     */
    h1FontSize: C.h1FontSize,
    h1LineHeight: C.h1LineHeight,
    /**
     * 48px in default theme
     */
    h2FontSize: C.h2FontSize,
    /**
     * 24px in default theme
     */
    h3FontSize: C.h3FontSize,
    /**
     * 18px in default theme
     */
    h4FontSize: C.h4FontSize,
    /**
     * 14px in default theme
     */
    smallFontSize: C.smallFontSize,
    /**
     * 96px in default theme
     */
    ultraBigFontSize: C.ultraBigFont,
    pPaddingBottom: "1.5rem",
  },
  disabled: V.gray,
  warning: V.rose,
};

const customTheme = {
  useSystemColorMode: false,
  breakpoints: createBreakpoints(C.breakpoints),
  fonts: {
    body: "BeausiteClassic",
  },
  space: {
    px: "1px",
    0.5: "0.125rem",
    1: "0.25rem",
    1.5: "0.375rem",
    2: "0.5rem",
    2.5: "0.625rem",
    3: "0.75rem",
    3.5: "0.875rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
  },
  sizes: {
    max: "max-content",
    min: "min-content",
    full: "100%",
    "3xs": "14rem",
    "2xs": "16rem",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "7xl": "80rem",
    "8xl": "90rem",
    container: {
      sm: "640px",
      md: "768px",
      lg: "1140px",
      xl: "1280px",
    },
  },
  radii: {
    none: "0",
    sm: "0.125rem",
    base: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
  colors: {
    ...C.theme,
    primary: "#FFFFFF", // White
    secondary: "#F2F2ED", // Light Grey
    tertiary: "#4158FF", // Peacock Blue
    tertiaryLight: "#7182FF", // Peacock Blue Hover
    black: "#141414",
    error: "#FF4646",
    disabled: "#d3d3d3",
    gray: "#828282",
    successGreen: "#c6f6d5", // Light Green
  },
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "tertiary",
        },
      },
    },
    Button: {
      baseStyle: {
        border: "1px",
        borderColor: "black",
        borderRadius: 0,
        transition: "0.3s",
        cursor: "pointer",
        lineHeight: "13px",
        _active: {
          boxShadow: "-3px 3px 14px 0px rgba(129, 67, 67, 0.2)",
        },
        _hover: {
          bgColor: "tertiaryLight",
          color: "primary",
          border: 0,
        },
      },
      sizes: {
        sm: {
          padding: "0.9rem 1rem",
          fontSize: "1rem",
          fontWeight: "normal",
        },
        md: {
          padding: "1rem 1.25rem",
          fontSize: "1.2rem",
          fontWeight: "normal",
        },
      },
      variants: {
        primary: {
          bgColor: "black",
          color: "primary",
          width: "none",
          borderRadius: "4.375rem",
          _hover: {
            bgColor: "#828282",
            color: "primary",
          },
        },
        secondary: {
          whiteSpace: "pre",
          bgColor: "tertiary",
          color: "white",
          borderRadius: "4.375rem",
        },
        ternary: {
          whiteSpace: "pre",
          bgColor: "transparent",
          color: "back",
          width: "none",
          borderRadius: "4.375rem",
          _hover: {
            bgColor: "tertiary",
            color: "white",
          },
        },
        outline: {
          bg: C.black,
          color: C.white,
          borderColor: C.white,
        },
        blackInverted: {
          bg: "black",
          color: C.white,
          _hover: {
            color: "black",
            border: "1px",
            borderColor: "black",
          },
          _active: {
            boxShadow: "-3px 3px 14px 0px rgba(129, 67, 67, 0.2)",
          },
        },
      },
      defaultProps: {
        variant: "base",
        size: "md",
      },
    },
    CheckBox: {
      baseStyle: {
        color: "black",
      },
    },
    h1: {
      baseStyle: {
        fontSize: ["2.5rem", null, null, "3.75rem", null],
        lineHeight: ["2.5rem", null, null, "3.75rem", null],
        letterSpacing: "-0.04em",
      },
    },
    h2: {
      baseStyle: {
        fontSize: ["1.88rem", null, null, "2.5rem", null],
        lineHeight: ["2.19rem", null, null, "2.81rem", null],
      },
    },
    h3: {
      baseStyle: {
        fontSize: ["1.44rem", null, null, "25px", null],
        lineHeight: ["1.75rem", null, null, "30px", null],
        fontWeight: "400",
        letterSpacing: "-0.04em",
      },
    },
    h4: {
      baseStyle: {
        fontSize: ["1.13rem", null, null, "1.7rem", null],
        lineHeight: ["1.375rem", null, null, "1.7rem", null],
      },
    },
    p: {
      baseStyle: {
        fontSize: ["0.95em", null, null, "1.25rem"],
        lineHeight: ["1.05rem", null, null, "1.5rem"],
        paddingBottom: "1.5rem",
      },
    },
  },
  custom: {
    datePicker: {
      default: {
        backgroundColor: "#1c1c24",
        color: C.white,
      },
      edges: {
        backgroundColor: "#357ebd",
        color: C.white,
      },
      selected: {
        backgroundColor: "#357ebd",
        color: C.white,
      },
      disabled: {
        backgroundColor: "#1c1c24",
        color: "#767676",
        // color: "#504f4f",
        // color: "#999999",
      },
      hover: {
        backgroundColor: "#292933",
        color: "#ffffff",
      },
      weekday: {
        backgroundColor: "#1c1c24",
        color: C.white,
      },
    },
  },
};

export type DefaultTheme = typeof legacyTheme & typeof customTheme;
/* The complete theme can be found here: https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme */
export const defaultTheme = extendTheme({
  ...legacyTheme,
  ...customTheme,
}) as DefaultTheme;
