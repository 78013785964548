import { useTheme } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import React from "react";

import {
  baseFontColor,
  blue,
  boldFontWeight,
  grayDark,
  grayLight,
  mediumScreen,
  smallFontSize,
  spacer,
  white,
} from "@styles/constants";
import { containerwidth } from "@styles/variables";

import { DefaultTheme, media } from ".";

export const GlobalStyle = () => {
  const theme: DefaultTheme = useTheme();

  return (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
          scroll-behavior: smooth;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body {
          margin: 0;
          min-width: 320px;
          font-family: ${theme.typography.baseFontFamily};
          font-size: ${theme.typography.baseFontSize};
          line-height: ${theme.typography.baseLineHeight};
          color: ${theme.colors.baseFont};
          overflow-x: hidden;
          -ms-overflow-style: none; /* Edge */
          scrollbar-width: none; /* Firefox */
        }
        body::-webkit-scrollbar {
          /* Chrome and Safari */
          display: none;
        }

        input,
        textarea,
        button {
          font-family: inherit;
        }

        h1 {
          font-size: ${theme.typography.h1FontSize};
          line-height: ${theme.typography.h1LineHeight};

          ${media.smallScreen`
            font-size: ${theme.typography.h2FontSize};
          `}
        }

        h2 {
          font-size: ${theme.typography.h2FontSize};
          line-height: 2rem;
        }

        h3 {
          font-size: ${theme.typography.h3FontSize};
          line-height: 1.7rem;
        }

        h4 {
          font-size: ${theme.typography.h4FontSize};
        }

        a {
          text-decoration: none;
          font-weight: normal;
          color: inherit;
        }

        p {
          line-height: 1.5rem;
        }

        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          outline: none;
          padding: 0;
        }

        ul {
          list-style: none;
        }

        #root,
        #__next {
          display: flex;
          min-height: 100vh;
          flex-direction: column;

          & > div:first-of-type {
            flex: 1;
          }
        }

        strong {
          font-weight: ${boldFontWeight};
        }

        em {
          font-style: italic;
        }

        .container {
          width: ${containerwidth};
          overflow: hidden;
          max-width: 100vw;
          margin: 0 auto;
          padding: 0 ${spacer / 2}rem;

          @media (max-width: ${mediumScreen}) {
            width: 100%;
          }
        }

        table {
          width: 100%;
          margin: ${spacer * 3}rem 0;
          font-size: ${smallFontSize};

          th {
            color: ${grayDark};
            text-align: left;
            padding: ${spacer}rem ${spacer / 2}rem;
          }

          tr {
            color: ${baseFontColor};
            border-bottom: 1px solid ${grayLight};
          }

          td {
            padding: ${spacer}rem ${spacer / 2}rem;
            vertical-align: middle;

            img {
              vertical-align: middle;
              margin-right: ${spacer}rem;
            }
          }
        }

        .checkbox {
          position: relative;
          display: inline;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0.4rem;
            top: 0.05rem;
            width: 0.3rem;
            height: 0.6rem;
            border: solid ${white};
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            z-index: 1;
          }

          input {
            display: none;

            &:checked ~ span {
              &:before {
                background-color: ${blue};
              }
            }
          }

          span {
            margin-left: ${spacer * 2}rem;

            &:before {
              background-color: ${white};
              border-radius: 0.2rem;
              border: 1px solid ${blue};
              content: "";
              display: block;
              height: 1rem;
              position: absolute;
              top: 0;
              width: 1rem;
            }
          }
        }
      `}
    />
  );
};
