import {
  ICheckoutModelLine,
  ICheckoutModelPrice,
} from "@the-volte/svc-core-sdk/lib/helpers";
import Script from "next/script";
import React from "react";

const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
export const GTMScript: React.FC = () =>
  gtmId ? (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `}
    </Script>
  ) : null;

export const GTMIframe: React.FC = () =>
  gtmId ? (
    <noscript
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display: none; visibility: hidden;" />`,
      }}
    />
  ) : null;

const gtmEvent = (
  eventName: Gtag.EventNames,
  data: Gtag.EventParams | Gtag.ControlParams | Gtag.CustomParams
) => {
  window.dataLayer?.push({
    event: eventName,
    ...data,
  });
};

export const gtmPurchaseEvent = (
  items: ICheckoutModelLine[],
  totalPrice: ICheckoutModelPrice
) => {
  try {
    if (gtmId && items.length) {
      const eventData: Gtag.EventParams = {
        items: items.map((i) => ({
          id: i.variant.sku,
          name: i.variant.product?.name,
          variant: i.variant.name,
          quantity: i.quantity,
          price: i.totalPrice?.net?.amount,
          currency: i.totalPrice?.net?.currency,
        })),
        value: totalPrice.gross.amount,
        currency: totalPrice.gross.currency,
      };
      gtmEvent("purchase", eventData);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
