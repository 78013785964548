import { css } from "@emotion/react";
import { CSSInterpolation } from "@emotion/serialize";

import {
  largeScreen,
  mediumScreen,
  smallScreen,
  xLargeScreen,
  xSmallScreen,
  xxLargeScreen,
  xxxLargeScreen,
} from "./constants";

const breakpointsInt = {
  largeScreen,
  mediumScreen,
  smallScreen,
  xSmallScreen,
  xLargeScreen,
  xxLargeScreen,
  xxxLargeScreen,
} as const;

type Breakpoints = keyof typeof breakpointsInt;
type Media = Record<
  Breakpoints,
  (l: TemplateStringsArray, ...p: any[]) => string
>;

export const media = Object.keys(breakpointsInt).reduce(
  (acc, label) => {
    acc[label as Breakpoints] = (
      literals: TemplateStringsArray,
      ...placeholders: CSSInterpolation[]
    ) =>
      css`
        @media (max-width: ${breakpointsInt[label as Breakpoints]}px) {
          ${css(literals, ...placeholders)}
        }
      ` as any;
    return acc;
  },
  // tslint:disable-next-line:no-object-literal-type-assertion
  {} as Media
);

export const minMedia = Object.keys(breakpointsInt).reduce(
  (acc, label) => {
    acc[label as Breakpoints] = (
      literals: TemplateStringsArray,
      ...placeholders: CSSInterpolation[]
    ) =>
      css`
        @media (min-width: ${breakpointsInt[label as Breakpoints]}px) {
          ${css(literals, ...placeholders)}
        }
      ` as any;
    return acc;
  },
  // tslint:disable-next-line:no-object-literal-type-assertion
  {} as Media
);
