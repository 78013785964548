import gql from "graphql-tag";

const priceFragment = gql`
  fragment PriceSF on Money {
    amount
    currency
  }
`;

const taxedPriceFragment = gql`
  ${priceFragment}
  fragment TaxedPrice on TaxedMoney {
    gross {
      ...PriceSF
    }
    net {
      ...PriceSF
    }
  }
`;

export const selectedAttributeFragment = gql`
  fragment SelectedAttributeFieldsSF on SelectedAttribute {
    attribute {
      id
      name
    }
    values {
      id
      name
    }
  }
`;

export const basicProductFragment = gql`
  ${selectedAttributeFragment}
  fragment BasicProductFields on Product {
    id
    slug
    name
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      alt
    }
    attributes {
      ...SelectedAttributeFieldsSF
    }
    retailValue {
      amount
      currency
    }
    resource {
      preparationDays
    }
    originalPurchasePrice {
      currency
      amount
    }
    hasWarehouseSale
  }
`;

export const productPricingFragment = gql`
  ${taxedPriceFragment}
  fragment ProductPricingFieldSF on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...TaxedPrice
        }
        stop {
          ...TaxedPrice
        }
      }
      priceRange {
        start {
          ...TaxedPrice
        }
        stop {
          ...TaxedPrice
        }
      }
    }
  }
`;

const featuredProductFragment = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${selectedAttributeFragment}
  fragment FeaturedProduct on Product {
    ...BasicProductFields
    ...ProductPricingFieldSF
    category {
      id
      name
    }
  }
`;

export const featuredProductsFragment = gql`
  ${featuredProductFragment}
  fragment FeaturedProducts on Query {
    collection(slug: "featured-products", channel: $channel) {
      id
      name
      backgroundImage {
        url
        alt
      }
      backgroundDesktopImage {
        url
        alt
      }
      products(first: 40, sortBy: { field: COLLECTION, direction: ASC }) {
        edges {
          node {
            ...FeaturedProduct
            shippingMethods(countryCode: "au") {
              name
              price {
                amount
              }
            }
            originalPurchasePrice {
              currency
              amount
            }
          }
        }
      }
      description
    }
  }
`;

export const attributeFragment = gql`
  fragment Attribute on Attribute {
    id
    name
    slug
    values(filterSoftDeleted: true) {
      id
      name
      slug
    }
  }
`;

export const shippingMethodsFragment = gql`
  ${selectedAttributeFragment}
  fragment ShippingMethods on Product {
    shippingMethods(countryCode: "au") {
      name
      price {
        amount
      }
    }
  }
`;

export const pageInfo = gql`
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
  }
`;

export const apiGatewayMenuItemFragment = `
  fragment ApiGatewayMenuItem on MenuItem {
    id
    name
    category {
      id
      name
      slug
    }
    url
    target
    collection {
      id
      name
      slug
    }
    page {
      slug
    }
    parent {
      id
    }
  }
`;
