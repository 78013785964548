import { ApolloClient, ApolloProvider } from "@apollo/client";
import {
  SaleorContext,
  SaleorContextType,
  SaleorManager,
  SaleorProvider,
} from "@the-volte/svc-core-sdk";
import React, { useEffect, useState } from "react";

interface IProps {
  config: any;
  children: React.ReactNode;
}

const SaleorSSRProvider: React.FC<IProps> = ({ config, children }: IProps) => {
  // Load and provide an SSR compatible Provider
  if (typeof window === "undefined") {
    if (global.saleorApi.api && global.saleorApi.apolloClient) {
      const { apolloClient, api } = global.saleorApi;

      const context = { api, config };
      if (apolloClient && context) {
        return (
          <SaleorContext.Provider value={context}>
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          </SaleorContext.Provider>
        );
      }

      return null;
    }

    return <>Error loading Apollo Client</>;
  }

  if (process.env.NODE_ENV === "development") {
    const [context, setContext] = useState<SaleorContextType | null>(null);
    const [client, setClient] = useState<ApolloClient<any> | null>(null);

    const getSaleorApiAndClient = async (manager: SaleorManager) => {
      const { api, apolloClient } = await manager.connect((saleorAPI) => {
        if (saleorAPI) {
          setContext({ api: saleorAPI, config });
        }
      });

      setContext({ api, config });
      setClient(apolloClient);
    };

    useEffect(() => {
      const manager = new SaleorManager(config);

      getSaleorApiAndClient(manager);
    }, []);

    if (client && context) {
      return (
        <SaleorContext.Provider value={context}>
          <ApolloProvider client={client}>{children}</ApolloProvider>
        </SaleorContext.Provider>
      );
    }

    return null;
  }

  return <SaleorProvider config={config}>{children}</SaleorProvider>;
};

SaleorSSRProvider.displayName = "SaleorSSRProvider";
export { SaleorSSRProvider };
