import { pageInfo } from "@the-volte/svc-core-sdk/lib/fragments/pageInfo";
import gql from "graphql-tag";

import {
  basicProductFragment,
  productPricingFragment,
  selectedAttributeFragment,
} from "@graphql/fragments";

export const searchResultsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${selectedAttributeFragment}
  ${pageInfo}
  query SearchResults(
    $query: String!
    $channel: String
    $after: String
    $pageSize: Int
  ) {
    products(
      filter: { search: $query }
      channel: $channel
      after: $after
      first: $pageSize
      public: true
    ) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingFieldSF
          category {
            id
            name
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;
