// colors
export const gray = "#c4c4c4";
export const rose = "#fe6e76";
export const white = "#fff";

// spacing
export const spacer = "1rem"; // 16px

// grid
export const containerwidth = "1140px";

// message
export const messageshadow = "0px 6px 15px 3px rgba(0, 0, 0, 0.25)";

// breakpoints
const mediumscreen = "992px";
const smallscreen = "540px";

export const mediumScreen = mediumscreen;
export const smallScreen = smallscreen;
