import { UknownObject } from "./tsUtils";

export function maybe<T>(exp: () => T): T | undefined;
export function maybe<T>(exp: () => T, d: T): T;
export function maybe(exp: any, d?: any) {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
}

export function filterNotEmptyArrayItems<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function pick(obj: UknownObject, keys: string[]): UknownObject {
  return { ...keys.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}) };
}

export const isPurchase = (rentalPeriod: number | null | undefined) =>
  rentalPeriod === 365;

type ExtractMetadataParameters = {
  slugs: string[];
  metaData: { slug: string; values: any[] }[];
};
type ExtractMetadataResult = { [key: string]: any[] };
export const extractMetadata = ({
  slugs,
  metaData,
}: ExtractMetadataParameters): ExtractMetadataResult => {
  if (metaData.length === 0) {
    return {};
  }

  const extractedMetadata = {};
  slugs.forEach((s) => {
    metaData.forEach((o, i) => {
      if (o.slug === s) {
        extractedMetadata[`${s}`] = metaData[i].values;
      }
    });
  });
  return extractedMetadata;
};
