// colors
const autofillColor = "rgb(250, 255, 189)";
const autofillColorSelected = "rgb(232, 240, 254)";
export const baseFontColor = "#323232";
const baseFontColorSemiTransparent = "rgba(50,50,50,0.6)";
const baseFontColorTransparent = "rgba(50,50,50,0.1)";
export const black = "#323232";
const blackOrigin = "#000";
export const blue = "#1b1b1d";
export const blueDark = "#0d0c0f";
const blueLight = "#7d7d86";
const blueOverlay = `rgba(125,125,134,0.1)`;
const blueOverlayDark = `rgba(125,125,134,0.2)`;
export const gray = "#7d7d7d";
const gray60 = "#767676";
export const grayMedium = "#c4c4c4";
export const grayDark = "#7d7d7d";
export const grayLight = "#f1f5f5";
const green = "#7d7d86";
const overlayColor = "rgba(199, 207, 207, 0.8)";
const rose = "#fe6e76";
const roseHalfTransparent = "#fe6e7680";
const red = "#e0444e";

export const accentBlue = "#69a2f8";
const accentBlueDark = "#355480";
export const accentBlueLight = "#b9cff0";
const accentBlueTransparent = "rgba(185, 207, 240, 0.1)";
export const white = "#fff";
const tabelGray = "#eaeaea";
const darkGreen = "#535a5a";
const secondaryGrey = "#EBF1F6";
const secondaryGreyDark = "#9D9FB1";
const secondaryBlue = "#2f2f36";
const whiteSemiTransparent = "rgba(255, 255, 255, 0.65)";
const whiteSlightlyTransparent = "rgba(255, 255, 255, 0.95)";
export const blackSemiTransparent = "rgba(0, 0, 0, 0.6)";

// theme colors
export const theme = {
  activeMenuOption: darkGreen,
  autofill: autofillColor,
  autofillSelected: autofillColorSelected,
  bannerBackground: secondaryGrey,
  bannerEdge: secondaryGreyDark,
  bannerLink: secondaryBlue,
  baseFont: baseFontColor,
  baseFontColorSemiTransparent,
  baseFontColorTransparent,
  dark: black,
  black: blackOrigin,
  disabled: gray,
  divider: grayLight,
  dividerDark: grayMedium,
  error: rose,
  hoverLightBackground: accentBlueLight,
  light: grayLight,
  lightFont: gray,
  listAttributeName: baseFontColorSemiTransparent,
  listBullet: darkGreen,
  overlay: overlayColor,
  secondaryOverlay: blueOverlay,
  secondaryOverlayDark: blueOverlayDark,
  success: green,
  tabTitle: darkGreen,
  tableDivider: tabelGray,
  tabsBorder: baseFontColorTransparent,
  thumbnailBorder: darkGreen,

  white,
  whiteSemiTransparent,
  whiteSlightlyTransparent,
  blackSemiTransparent,
  primary: {
    base: accentBlue,
    50: accentBlueTransparent,
    100: accentBlueLight,
    200: accentBlueLight,
    300: accentBlueLight,
    400: accentBlueLight,
    500: accentBlue,
    600: accentBlueDark,
    700: accentBlueDark,
    800: accentBlueDark,
    900: accentBlueDark,
    1000: accentBlueDark,
  },
  secondary: {
    base: blue,
    50: blueLight,
    100: blueLight,
    200: blueLight,
    300: blueLight,
    400: blueLight,
    500: blue,
    600: blueDark,
    700: blueDark,
    800: blueDark,
    900: blueDark,
  },
  legacy: {
    gray,
    gray60,
    grayDark,
    grayLight,
    grayMedium,
    accentBlue,
    accentBlueDark,
    accentBlueLight,
  },
  components: {
    wishlistHeart: {
      unliked: {
        fill: "transparent",
        stroke: white,
        "&:hover": {
          fill: roseHalfTransparent,
          stroke: rose,
        },
      },
      liked: {
        fill: red,
        stroke: red,
      },
    },
    UnConfirmedOrderBanner: {
      textColor: red,
    },
  },
};

// typography
export const baseFontFamily = "BeausiteClassic";
export const baseFontSize = "1rem"; // 16px
export const baseLineHeight = "1.25rem"; // 20px
export const boldFontWeight = 600;
export const extraBoldFontWeight = 800;
export const h1FontSize = "4rem"; // 64px
export const h2FontSize = "3rem"; // 48px
export const h1LineHeight = 1;
export const h3FontSize = "1.5rem"; // 24px
// export const lenderNameSize = "1.625rem"; // 26px
export const lenderNameSize = "1.5rem"; // 24px
export const TitleLargeSize = "1.75rem"; // 28px
export const h4FontSize = "1.125rem"; // 18px
export const labelFontSize = "0.75rem"; // 12px
export const noticeFontSize = "0.813rem"; // 13px
export const smallFontSize = "0.875rem"; // 14px
export const ultraBigFont = "6rem"; // 96px
export const OverlayMediumFont = "1.875rem"; // 30px
export const OverlayBigFont = "2.5rem"; // 40px

// spacing
export const spacer = 1; // rem
export const fieldSpacer = "1.875rem";

// breakpoints
export const xxxLargeScreen = 1920;
export const xxLargeScreen = 1440;
export const xLargeScreen = 1280;
export const largeScreen = 992;
export const mediumScreen = 720;
export const smallScreen = 540;
export const xSmallScreen = 375;
export const breakpoints = {
  xs: `${xSmallScreen}px`,
  sm: `${smallScreen}px`,
  md: `${mediumScreen}px`,
  lg: `${largeScreen}px`,
  xl: `${xLargeScreen}px`,
  xxl: `${xxLargeScreen}px`,
  xxxl: `${xxxLargeScreen}px`,
} as const;

// Component Size
export const navMenuXLHeight = "72px";
export const navMenuSearchMobileHeight = "61px";
export const navMenuSearchXLHeight = "91px";
