import * as cryptoNode from "crypto";

export const hashData = async (data: string) => {
  if (crypto?.subtle) {
    // crypto.subtle is only available in client side environments
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
  }

  // use cryptoNode if util is used in ssr or api routes
  return cryptoNode.createHash("sha256").update(data).digest("hex");
};
