import React from "react";

// FIXME: Intl is not being used. At the moment we are just removing the placeholders.

// TODO: write simple function to replace placeholders with any provided value
// e.g. so when called from /src/components/atoms/AddNewTile/AddNewTile.tsx we use the values
const intl = {
  formatMessage: ({ defaultMessage }) => defaultMessage.replace(/\{.*?\}/g, ""),
};

const useIntl = () => intl;

const formatDateValue = (value: string) => {
  try {
    const date = new Date(value);
    return date.toLocaleDateString();
  } catch {
    return value;
  }
};

const FormattedMessage = ({ defaultMessage }) => (
  <>{defaultMessage?.replace(/\{.*?\}/g, "")}</>
);

const FormattedDate = ({ value }) => <>{formatDateValue(value)}</>;

const defineMessages = (params) => params;

export { useIntl, FormattedMessage, defineMessages, FormattedDate };
